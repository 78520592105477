import React, {Fragment, useEffect, useState} from "react";
import {Box, Grid, Stack, styled, Typography} from "@mui/material";
import {
  GAME_STATUS_FINISHED,
  GAME_STATUS_FORFEIT,
  GAME_STATUS_INPROGRESS,
  GAME_STATUS_NOT_STARTED,
  GAME_STATUS_REPORTED, GAME_STATUS_STARTING
} from "../../components/Global/Global";
import {Link} from "react-router-dom";
import Blason from "../Blason/Blason";

const GameLineStyled = styled("div")(({theme}) => ({
    ['.bbsa-game-line-root']: {
      backgroundColor: theme.palette.background.paper
    },
    ['.bbsa-game-line-team-name']: {
      fontFamily: theme.typography.fontFamily,
    },
    ['.bbsa-game-line-team-blason']: {
      height: "32px"
    },
    ['p.LOSE']: {
      color: theme.palette.text.disabled,
    },
    ['p.WIN']: {
      fontWeight: "bold",
    },
    ['.bbsa-game-line-score p']: {
      fontFamily: theme.typography.fontFamily,
      fontSize: "1.5em",
      fontWeight: "bolder",
      paddingLeft: "3px",
      paddingRight: "3px",
      display: "inline-block",
    },
    [theme.breakpoints.down("sm")]: {
      ['.bbsa-game-line-team-name']: {
        fontSize: "0.7rem",
      },
      ['.bbsa-game-line-score p']: {
        fontFamily: theme.typography.fontFamily,
        fontSize: "1em",
        paddingLeft: "3px",
        paddingRight: "3px",
      },
    }
  }
));

export default function GameLine(props) {
  const {game} = props;
  const [team1, setTeam1] = useState(null)
  const [team2, setTeam2] = useState(null)
  const [state1, setState1] = useState(null)
  const [state2, setState2] = useState(null)

  useEffect(() => {
    let tp1 = game.teamParticipations.filter(t => t.position === 1)[0]
    let tp2 = game.teamParticipations.filter(t => t.position === 2)[0]
    setState1(tp1)
    setState2(tp2)
    if(tp1){
      setTeam1(tp1.team)
    }
    if(tp2){
      setTeam2(tp2.team)
    }
  }, [])

  return (
    <GameLineStyled>
      <Link to={"/game/" + game.id}>
        <Box className={"bbsa-game-line-root"}>
          <Grid container>
            <Grid item xs={5}>
              <Stack
                direction={"row"}
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
              >
                <Typography className={"bbsa-game-line-team-name " + (state1 ? state1.gameResult : "")}
                            align={"right"}>{team1 !== null ? team1.name : ""}</Typography>
                <Blason src={team1.blasonUrl}/>
                <img className={"bbsa-game-line" +
                    "-team-blason"} src={team1 !== null ? team1.blasonUrl : ""}/>
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <GameLineScore game={game}/>
            </Grid>
            <Grid item xs={5}>
              <Stack
                direction={"row"}
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <img className={"bbsa-game-line-team-blason"} src={team2 !== null ? team2.blasonUrl : ""}/>
                <Typography className={"bbsa-game-line-team-name " + (state2 ? state2.gameResult : "")}
                            align={"left"}>{team2 !== null ? team2.name : ""}</Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Link>
    </GameLineStyled>
  )
}

function GameLineScore(props) {
  const {game} = props;
  const [state1, setState1] = useState("0")
  const [state2, setState2] = useState("0")

  useEffect(() => {
    setState1(game.teamParticipations.filter(t => t.position === 1)[0])
    setState2(game.teamParticipations.filter(t => t.position === 2)[0])
  })

  if (game.progressStatus === GAME_STATUS_FINISHED) {
    return (
      <Fragment>
        <Typography align={"center"} className={"bbsa-game-line-score"}>
          <Typography className={"score1 " + state1.gameResult}>
            {state1.score}
          </Typography>
          <Typography>
            -
          </Typography>
          <Typography className={"score2 " + state2.gameResult}>
            {state2.score}
          </Typography>
        </Typography>
      </Fragment>
    )
  }

  if (game.progressStatus === GAME_STATUS_FORFEIT) {
    return (
      <Fragment>
        <Typography align={"center"} className={"bbsa-game-line-score small"}>
          <Typography>
            forfait
          </Typography>
        </Typography>
      </Fragment>
    )
  }

  if ((game.progressStatus === GAME_STATUS_REPORTED)) {
    return (
      <Fragment>
        <Typography align={"center"} className={"bbsa-game-line-score small"}>
          <Typography>
            Reporté
          </Typography>
        </Typography>
      </Fragment>
    )
  }

  if ((game.progressStatus === GAME_STATUS_NOT_STARTED)) {
    return (
      <Fragment>
        <Typography align={"center"} className={"bbsa-game-line-score small"}>
          <Typography>
            {game.time ? game.time : "--:--"}
          </Typography>
        </Typography>
      </Fragment>
    )
  }

  if ((game.progressStatus === GAME_STATUS_INPROGRESS)) {
    return (
      <Fragment>
        <Typography align={"center"} className={"bbsa-game-line-score small"}>
          <Typography>
            En cours
          </Typography>
        </Typography>
      </Fragment>
    )
  }

  if ((game.progressStatus === GAME_STATUS_STARTING)) {
    return (
      <Fragment>
        <Typography align={"center"} className={"bbsa-game-line-score small"}>
          <Typography>
            Sifflé
          </Typography>
        </Typography>
      </Fragment>
    )
  }

}
